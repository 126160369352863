
import { defineComponent, ref, onMounted, watch } from 'vue';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { useCompanyStore } from '@/store/CompanyStore';
import { IFractionData } from './ListPage.vue';
import { useFilterMainStore } from '@/store/FilterMainStore';

export interface IFractionResponse {
  data: {
    error: boolean,
    message: string,
    data: IFractionData
    errorCode: string
  },
  status: number
}

function checkEmptyFields(obj: IFractionData): boolean {
  for (let key in obj) {
    if (obj[key] === "" || obj[key] === 0) {
      return true;
    }
  }
  return false;
}

export default defineComponent({
  name: "FractionPost",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  },
  setup() {
    const { showTimeAlert } = useAlert()
    const initData: IFractionData = {
      unit_sku: "",
      sku: "",
      value: 0,
      measure: "",
      name: null
    }
    const postObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data: null | IFractionData
    }>({
      isLoading: false,
      noError: true,
      data: { ...initData }
    })
    const loaderStore = useLoaderStore();
    const companyStore = useCompanyStore();
    const filterMainStore = useFilterMainStore()
    const isRequiredField = ref(false)
    const isLoadSelect = ref(false)
    const options = ref()

    // Functions
    async function getWholeProductList() {
      isLoadSelect.value = true
      options.value = []
      try{
        const response = await axios.get('/api/getWholeProductList')
        response.data.data.forEach(element => {
          options.value.push({
            value: element.sku,
            label: element.name
          })
        })
        isLoadSelect.value = false
      }catch(error){
        isLoadSelect.value = false
        if(error instanceof Error){
          error.message, "error"
        }
      }
    }

    async function onSubmitForm() {
      isRequiredField.value = false
      const { data } = postObject.value
      if(!data)
        return
      if(checkEmptyFields(data))
        return isRequiredField.value = true
      loaderStore.open()
      try {
        const { data } = postObject.value
        if(data) {
          const { sku, unit_sku, measure, value } = data
          const result: IFractionResponse = await axios.post(`/api/postFraction`, { sku, unit_sku, measure, value })
          showTimeAlert("Produto Fracionado criado com sucesso!")
          postObject.value.data = { ...initData }
          return router.push("/produtos-fracionados")
        }
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
        loaderStore.close()
      }
    }

    function handleInitFilterModal() {
      if(!companyStore.getId) {
        filterMainStore.hideModal()
        filterMainStore.showModal()
      }
    }

    // Lifecycles
    onMounted(() => {
      handleInitFilterModal()
      getWholeProductList()
    })

    watch(() => companyStore.getCompany, () => getWholeProductList(), { deep: true })

    return {
      postObject,
      isRequiredField,
      onSubmitForm,
      isLoadSelect,
      options,
      getWholeProductList
    }
  }
})
